import { useState, useEffect } from 'react';
import { 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  query, 
  orderBy, 
  onSnapshot 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, collections, storage } from '../../firebase';
import { motion } from 'framer-motion';

const IMGBB_API_KEY = process.env.REACT_APP_IMGBB_API_KEY;

const AnnouncementManager = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState({
    message: '',
    link: '',
    bgImage: '',
    startDate: '',
    endDate: '',
    isActive: true
  });
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const q = query(collections.announcements, orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setAnnouncements(
        snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      );
    });

    return () => unsubscribe();
  }, []);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setUploading(true);
      console.log('Starting upload...');

      const formData = new FormData();
      formData.append('key', '47f154e459278843d64a4697c2e4a06c');
      formData.append('image', file);

      const response = await fetch('https://api.imgbb.com/1/upload', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      console.log('Upload response:', data);

      if (data.success) {
        setNewAnnouncement(prev => ({
          ...prev,
          bgImage: data.data.display_url
        }));
        console.log('Upload successful!');
      } else {
        throw new Error('Upload failed: ' + (data.error?.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Failed to upload image: ' + error.message);
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await addDoc(collections.announcements, {
        ...newAnnouncement,
        createdAt: new Date().toISOString()
      });
      
      setNewAnnouncement({
        message: '',
        link: '',
        bgImage: '',
        startDate: '',
        endDate: '',
        isActive: true
      });
    } catch (error) {
      console.error('Error adding announcement:', error);
    }
  };

  const toggleStatus = async (id, currentStatus) => {
    try {
      await updateDoc(doc(db, 'announcements', id), {
        isActive: !currentStatus
      });
    } catch (error) {
      console.error('Error updating announcement:', error);
    }
  };

  const deleteAnnouncement = async (id) => {
    try {
      await deleteDoc(doc(db, 'announcements', id));
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-12">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white p-8 rounded-lg shadow-sm"
      >
        <h2 className="text-3xl font-heading mb-8">Create Announcement</h2>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Image Upload Preview */}
          <div className="relative h-48 bg-gray-100 rounded-lg overflow-hidden">
            {newAnnouncement.bgImage ? (
              <img 
                src={newAnnouncement.bgImage} 
                alt="Background preview" 
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <span className="text-gray-400">No image selected</span>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="absolute inset-0 opacity-0 cursor-pointer"
            />
            {uploading && (
              <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                <span className="text-white">Uploading...</span>
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium mb-2">Start Date</label>
              <input
                type="datetime-local"
                value={newAnnouncement.startDate}
                onChange={(e) => setNewAnnouncement(prev => ({
                  ...prev,
                  startDate: e.target.value
                }))}
                className="w-full border-gray-200 rounded-lg p-3"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">End Date</label>
              <input
                type="datetime-local"
                value={newAnnouncement.endDate}
                onChange={(e) => setNewAnnouncement(prev => ({
                  ...prev,
                  endDate: e.target.value
                }))}
                className="w-full border-gray-200 rounded-lg p-3"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Message</label>
            <textarea
              value={newAnnouncement.message}
              onChange={(e) => setNewAnnouncement(prev => ({
                ...prev,
                message: e.target.value
              }))}
              rows={4}
              className="w-full border-gray-200 rounded-lg p-3"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Link (optional)</label>
            <input
              type="url"
              value={newAnnouncement.link}
              onChange={(e) => setNewAnnouncement(prev => ({
                ...prev,
                link: e.target.value
              }))}
              className="w-full border-gray-200 rounded-lg p-3"
            />
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={newAnnouncement.isActive}
              onChange={(e) => setNewAnnouncement(prev => ({
                ...prev,
                isActive: e.target.checked
              }))}
              className="w-4 h-4"
            />
            <span className="text-sm">Active</span>
          </div>

          <button
            type="submit"
            className="w-full bg-black text-white py-3 rounded-lg hover:bg-black/90 transition-colors"
          >
            Create Announcement
          </button>
        </form>
      </motion.div>

      {/* Announcements List */}
      <div className="space-y-6">
        <h3 className="text-2xl font-heading">Current Announcements</h3>
        
        <div className="grid gap-6">
          {announcements.map((announcement) => (
            <motion.div 
              key={announcement.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="relative overflow-hidden rounded-lg shadow-sm"
            >
              <div className="relative h-48">
                {announcement.bgImage ? (
                  <img 
                    src={announcement.bgImage} 
                    alt="" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-100" />
                )}
                <div className="absolute inset-0 bg-black/40" />
                <div className="absolute inset-0 p-6 flex flex-col justify-between text-white">
                  <div>
                    <p className="text-lg font-medium">{announcement.message}</p>
                    {announcement.link && (
                      <a 
                        href={announcement.link}
                        className="text-sm underline hover:text-accent mt-2 inline-block"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn More
                      </a>
                    )}
                  </div>
                  
                  <div className="flex justify-between items-end">
                    <div className="text-sm">
                      {announcement.startDate && (
                        <p>Starts: {new Date(announcement.startDate).toLocaleDateString()}</p>
                      )}
                      {announcement.endDate && (
                        <p>Ends: {new Date(announcement.endDate).toLocaleDateString()}</p>
                      )}
                    </div>
                    
                    <div className="flex space-x-2">
                      <button
                        onClick={() => toggleStatus(announcement.id, announcement.isActive)}
                        className={`px-3 py-1 rounded text-sm ${
                          announcement.isActive 
                            ? 'bg-green-500' 
                            : 'bg-gray-500'
                        }`}
                      >
                        {announcement.isActive ? 'Active' : 'Inactive'}
                      </button>
                      
                      <button
                        onClick={() => deleteAnnouncement(announcement.id)}
                        className="px-3 py-1 bg-red-500 rounded text-sm hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementManager; 