import { useState } from 'react';
import * as S from './styles/AdminStyles';

const HeroManager = () => {
  const [heroContent, setHeroContent] = useState({
    title: "EOG ATELIER",
    subtitle: "Crafting Timeless Elegance",
    backgroundVideo: "/video/hero-background.mp4"
  });

  return (
    <S.ManagerContainer>
      <S.ContentCard>
        <S.Form>
          <S.FormGroup>
            <S.Label>Hero Title</S.Label>
            <S.Input 
              value={heroContent.title}
              onChange={(e) => setHeroContent({...heroContent, title: e.target.value})}
            />
          </S.FormGroup>

          <S.FormGroup>
            <S.Label>Subtitle</S.Label>
            <S.Input 
              value={heroContent.subtitle}
              onChange={(e) => setHeroContent({...heroContent, subtitle: e.target.value})}
            />
          </S.FormGroup>

          <S.FormGroup>
            <S.Label>Background Video</S.Label>
            <S.Input type="file" accept="video/*" />
            <div style={{ marginTop: '0.5rem', opacity: 0.6, fontSize: '0.9rem' }}>
              Current: {heroContent.backgroundVideo}
            </div>
          </S.FormGroup>

          <S.PreviewPanel>
            <h3 style={{ marginBottom: '1rem' }}>Preview</h3>
            <div style={{ textAlign: 'center' }}>
              <h1>{heroContent.title}</h1>
              <p>{heroContent.subtitle}</p>
            </div>
          </S.PreviewPanel>

          <S.ActionBar>
            <S.Button primary>Save Changes</S.Button>
            <S.Button>Reset</S.Button>
          </S.ActionBar>
        </S.Form>
      </S.ContentCard>
    </S.ManagerContainer>
  );
};

export default HeroManager; 