import { useState } from 'react';
import * as S from './styles/AdminStyles';

const ProcessManager = () => {
  const [processSteps, setProcessSteps] = useState([
    {
      id: 1,
      title: "Initial Consultation",
      description: "One-on-one meeting to discuss your vision and requirements"
    },
    {
      id: 2,
      title: "Design Development",
      description: "Creating detailed sketches and selecting materials"
    },
    {
      id: 3,
      title: "Crafting & Refinement",
      description: "Expert artisans bring the design to life with meticulous attention to detail"
    }
  ]);

  return (
    <S.ManagerContainer>
      <S.TopBar>
        <S.Button primary whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
          <span>+</span> Add Process Step
        </S.Button>
      </S.TopBar>

      <div style={{ display: 'grid', gap: '1rem' }}>
        {processSteps.map((step, index) => (
          <S.ItemCard
            key={step.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div style={{ 
              width: '40px', 
              height: '40px', 
              borderRadius: '50%', 
              background: 'rgba(255,255,255,0.1)', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center' 
            }}>
              {index + 1}
            </div>
            <div style={{ flex: 1 }}>
              <h3 style={{ marginBottom: '0.5rem' }}>{step.title}</h3>
              <p style={{ opacity: 0.7 }}>{step.description}</p>
            </div>
            <S.ActionBar>
              <S.Button>Edit</S.Button>
              <S.Button>↑</S.Button>
              <S.Button>↓</S.Button>
              <S.Button style={{ background: 'rgba(255, 0, 0, 0.1)' }}>Delete</S.Button>
            </S.ActionBar>
          </S.ItemCard>
        ))}
      </div>
    </S.ManagerContainer>
  );
};

export default ProcessManager; 