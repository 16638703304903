import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --colors--white: whitesmoke;
    --colors--black: #18181a;
    --colors--grey: #999;
    --colors--yellow: #f9c518;
    --colors--light-grey: #d4d4d4;
    --colors--blue: #172e41;
    --colors--dark-grey: #212121;
    
    --font-family--neue-montreal: "Neue Montreal", sans-serif;
    
    --font-size--16px: 16px;
    --font-size--18px: 18px;
    --font-size--21px: 21px;
    --font-size--24px: 24px;
    --font-size--48px: 48px;
    --font-size--60px: 60px;
    
    --line-height--1-1em: 1.1em;
    --line-height--1-3em: 1.3em;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  html.lenis {
    height: auto;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto !important;
  }

  body {
    font-family: var(--font-family--neue-montreal);
    background-color: var(--colors--black);
    color: var(--colors--white);
    line-height: var(--line-height--1-3em);
  }
`;

export default GlobalStyles; 