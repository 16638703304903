import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const LouisVuittonCollection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.8 }}
    >
      <BackButton
        to="/"
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        whileHover={{ x: -5 }}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <span>Back to Collections</span>
      </BackButton>

      <Hero>
        <motion.video
          autoPlay
          muted
          loop
          playsInline
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 2 }}
        >
          <source src="/louis/2588120_Suitcase_Luggage_1920x1080.mp4" type="video/mp4" />
        </motion.video>
        <motion.h1
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Louis Vuitton
          <span>Atelier Collection 2022</span>
        </motion.h1>
      </Hero>

      <ContentSection>
        <TextBlock
          initial={{ y: 30, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ margin: "-100px" }}
          transition={{ duration: 0.6 }}
        >
          <h2>Behind The Scenes</h2>
          <p>
            An intimate look into the creative process and the meticulous attention 
            to detail that goes into every piece. From initial sketches to final fittings, 
            this collection represents months of collaborative work with Louis Vuitton's 
            finest artisans.
          </p>
        </TextBlock>

        <BentoGrid>
          <BentoItem
            className="large"
            initial={{ scale: 0.95, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ margin: "-100px" }}
            transition={{ duration: 0.8 }}
          >
            <motion.img 
              src="/louis/louis1.jpg" 
              alt="Studio preparation"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            />
            <ItemCaption>Studio preparation and collection curation</ItemCaption>
          </BentoItem>

          <BentoItem
            initial={{ scale: 0.95, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ margin: "-100px" }}
            transition={{ duration: 0.8, delay: 0.1 }}
          >
            <motion.img 
              src="/louis/louis3.jpg"
              alt="Design process"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            />
            <ItemCaption>Design refinement and material selection</ItemCaption>
          </BentoItem>
        </BentoGrid>

        <TextBlock
          initial={{ y: 30, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ margin: "-100px" }}
          transition={{ duration: 0.6 }}
        >
          <h2>The Atelier</h2>
          <p>
            Within the walls of Louis Vuitton's prestigious atelier, each piece is 
            brought to life through a harmonious blend of traditional craftsmanship 
            and contemporary vision. Our team of artisans works tirelessly to ensure 
            every detail meets the highest standards of luxury.
          </p>
        </TextBlock>

        <BentoGrid>
          <BentoItem
            initial={{ scale: 0.95, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ margin: "-100px" }}
            transition={{ duration: 0.8 }}
          >
            <motion.img 
              src="/louis/louis4.jpg" 
              alt="Craftsmanship details"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            />
            <ItemCaption>Detailed craftsmanship process</ItemCaption>
          </BentoItem>
          <BentoItem
            className="large"
            initial={{ scale: 0.95, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ margin: "-100px" }}
            transition={{ duration: 0.8, delay: 0.1 }}
          >
            <motion.img 
              src="/louis/louis5.jpg" 
              alt="Collection overview"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            />
            <ItemCaption>Collection overview and styling</ItemCaption>
          </BentoItem>
        </BentoGrid>

        <BentoGrid>
          <BentoItem
            className="large"
            initial={{ scale: 0.95, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ margin: "-100px" }}
            transition={{ duration: 0.8 }}
          >
            <motion.img 
              src="/louis/louis6.jpg" 
              alt="Final touches"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            />
            <ItemCaption>Final touches and quality control</ItemCaption>
          </BentoItem>
          <BentoItem
            initial={{ scale: 0.95, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ margin: "-100px" }}
            transition={{ duration: 0.8, delay: 0.1 }}
          >
            <motion.img 
              src="/louis/louis7.jpg" 
              alt="Collection details"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            />
            <ItemCaption>Intricate details and finishing</ItemCaption>
          </BentoItem>
        </BentoGrid>
      </ContentSection>
    </PageContainer>
  );
};

const PageContainer = styled(motion.main)`
  background: #000000;
  color: white;
  min-height: 100vh;
`;

const Hero = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 60vh;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);
    z-index: 1;
  }

  h1 {
    font-size: clamp(3rem, 8vw, 6rem);
    line-height: 1;
    z-index: 2;
    color: white;
    mix-blend-mode: difference;
    
    span {
      display: block;
      font-size: 0.5em;
      opacity: 0.8;
    }
  }
`;

const ContentSection = styled.section`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const TextBlock = styled(motion.div)`
  max-width: 800px;
  margin: 4rem auto;

  h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 1.5rem;
  }

  p {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    line-height: 1.6;
    color: rgba(255,255,255,0.8);
  }
`;

const BentoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const BentoItem = styled(motion.div)`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  background: #111;

  @media (min-width: 768px) {
    grid-column: ${props => props.className === 'large' ? 'span 8' : 'span 4'};
  }

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ItemCaption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  font-size: 0.9rem;
  opacity: 0.8;
`;

const ProcessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
`;

const ProcessStep = styled(motion.div)`
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--colors--white);
  }

  p {
    color: rgba(255,255,255,0.8);
    line-height: 1.6;
  }
`;

const BackButton = styled(motion(Link))`
  position: fixed;
  top: 6rem;
  left: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  z-index: 100;
  padding: 0.75rem 1rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: background 0.3s ease;

  svg {
    width: 20px;
    height: 20px;
    stroke: currentColor;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    top: 5rem;
    left: 1rem;
    padding: 0.5rem 0.75rem;
    
    span {
      display: none; // Hide text on mobile, show only icon
    }
  }
`;

export default LouisVuittonCollection; 