import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Biography = () => {
  return (
    <PageContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.8 }}
    >
      <Hero>
        <motion.h1
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Erickson Oliveira Grady
          <span>Fashion Designer & Innovator</span>
        </motion.h1>
      </Hero>

      <ContentSection>
        <BioGrid>
          <TextBlock>
            <h2>The Early Years</h2>
            <p>
              Born in Verona, Italy, Erickson Oliveira Grady's journey in fashion began 
              long before he could thread a needle. His early years in Italy's fashion capital 
              region shaped his artistic vision and craftsmanship. His exposure to his 
              grandmother's tailoring business laid the foundation for what would become 
              a revolutionary career in fashion. In 2013, he made the pivotal decision to 
              establish his permanent base in the United States, bringing his distinctive 
              European sensibilities to the American fashion landscape.
            </p>
          </TextBlock>

          <BentoSection>
            <BentoImage span="span 3">
              <img src={bentoImages[0].src} alt={bentoImages[0].caption} />
              <BentoCaption>{bentoImages[0].caption}</BentoCaption>
            </BentoImage>
            <BentoImage span="span 3">
              <img src={bentoImages[1].src} alt={bentoImages[1].caption} />
              <BentoCaption>{bentoImages[1].caption}</BentoCaption>
            </BentoImage>
            <BentoImage span="span 3">
              <img src={bentoImages[2].src} alt={bentoImages[2].caption} />
              <BentoCaption>{bentoImages[2].caption}</BentoCaption>
            </BentoImage>
            <BentoImage span="span 3">
              <img src={bentoImages[3].src} alt={bentoImages[3].caption} />
              <BentoCaption>{bentoImages[3].caption}</BentoCaption>
            </BentoImage>
          </BentoSection>

          <TextBlock>
            <h2>Rise to Prominence</h2>
            <p>
              Growing up in the heart of Italy's fashion scene, Erickson's talent emerged early 
              through his work in local ateliers. His apprenticeship with master tailors in 
              Verona and later Milan shaped his understanding of traditional Italian craftsmanship. 
              These formative years in Italy's prestigious fashion industry laid the groundwork 
              for his distinctive style, blending classical Italian tailoring with contemporary 
              design sensibilities.
            </p>
          </TextBlock>

          <BentoSection>
            <BentoImage span="span 6">
              <img src={bentoImages[4].src} alt={bentoImages[4].caption} />
              <BentoCaption>{bentoImages[4].caption}</BentoCaption>
            </BentoImage>
            <BentoImage span="span 3">
              <img src={bentoImages[5].src} alt={bentoImages[5].caption} />
              <BentoCaption>{bentoImages[5].caption}</BentoCaption>
            </BentoImage>
            <BentoImage span="span 3">
              <img src={bentoImages[6].src} alt={bentoImages[6].caption} />
              <BentoCaption>{bentoImages[6].caption}</BentoCaption>
            </BentoImage>
          </BentoSection>

          <Milestone>
            <h3>Career Milestones</h3>
            <Timeline>
              <TimelineItem>
                <Year>1983</Year>
                <Event>First collection debut in Naples</Event>
              </TimelineItem>
              <TimelineItem>
                <Year>1989</Year>
                <Event>Paris Fashion Week breakthrough</Event>
              </TimelineItem>
              <TimelineItem>
                <Year>1995</Year>
                <Event>Opened first atelier in Milan</Event>
              </TimelineItem>
              <TimelineItem>
                <Year>2001</Year>
                <Event>Launched sustainable fashion initiative</Event>
              </TimelineItem>
              <TimelineItem>
                <Year>2010</Year>
                <Event>Received Lifetime Achievement Award</Event>
              </TimelineItem>
            </Timeline>
          </Milestone>

          <BentoSection>
            <BentoImage span="span 4">
              <img src={bentoImages[7].src} alt={bentoImages[7].caption} />
              <BentoCaption>{bentoImages[7].caption}</BentoCaption>
            </BentoImage>
            <BentoImage span="span 4">
              <img src={bentoImages[8].src} alt={bentoImages[8].caption} />
              <BentoCaption>{bentoImages[8].caption}</BentoCaption>
            </BentoImage>
            <BentoImage span="span 4">
              <img src={bentoImages[9].src} alt={bentoImages[9].caption} />
              <BentoCaption>{bentoImages[9].caption}</BentoCaption>
            </BentoImage>
          </BentoSection>

          <TextBlock>
            <h2>Design Philosophy</h2>
            <p>
              Erickson's approach to fashion transcends mere clothing creation. His philosophy 
              interweaves sustainable practices with innovative design, creating pieces that are 
              both timeless and forward-thinking. His signature style, characterized by architectural 
              elements and fluid silhouettes, has redefined luxury fashion for the modern era.
            </p>
            <Quote>
              "Fashion is not just about creating beautiful garments; it's about crafting stories, 
              emotions, and experiences that resonate across generations."
            </Quote>
          </TextBlock>

          <BentoSection>
            <BentoImage span="span 12" ratio="16/9">
              <img 
                src={bentoImages[10].src} 
                alt={bentoImages[10].caption} 
                style={{ objectPosition: 'center top 10%' }} 
              />
              <BentoCaption>{bentoImages[10].caption}</BentoCaption>
            </BentoImage>
          </BentoSection>

          <TextBlock>
            <h2>Legacy & Future</h2>
            <p>
              Today, Erickson continues to push boundaries while mentoring the next generation 
              of designers. His commitment to sustainable luxury and innovation in digital fashion 
              demonstrates his ongoing evolution. With studios in New York, Paris, and Milan, his 
              influence on global fashion remains as strong as ever.
            </p>
          </TextBlock>

          <BentoSection>
            <BentoImage span="span 6">
              <img src={bentoImages[11].src} alt={bentoImages[11].caption} />
              <BentoCaption>{bentoImages[11].caption}</BentoCaption>
            </BentoImage>
            <BentoImage span="span 6">
              <img src={bentoImages[12].src} alt={bentoImages[12].caption} />
              <BentoCaption>{bentoImages[12].caption}</BentoCaption>
            </BentoImage>
          </BentoSection>
        </BioGrid>
      </ContentSection>
    </PageContainer>
  );
};

const bentoImages = [
  {
    src: "/bio/02.jpg",
    caption: "Early design sketches and prototypes"
  },
  {
    src: "/bio/03.jpg",
    caption: "First atelier in Milan"
  },
  {
    src: "/bio/04.jpg",
    caption: "Craftsmanship detail study"
  },
  {
    src: "/bio/05.jpg",
    caption: "Pattern development process"
  },
  {
    src: "/bio/88.jpg",
    caption: "Paris Fashion Week, 1995"
  },
  {
    src: "/bio/08.jpg",
    caption: "Studio workspace"
  },
  {
    src: "/bio/09.jpg",
    caption: "Collection presentation"
  },
  {
    src: "/bio/10.jpg",
    caption: "Design innovation process"
  },
  {
    src: "/bio/11.jpg",
    caption: "Material exploration"
  },
  {
    src: "/bio/12.jpg",
    caption: "Sustainable fashion initiative"
  },
  {
    src: "/bio/17.jpg",
    caption: "Mentoring session"
  },
  {
    src: "/bio/18.jpg",
    caption: "Creative direction"
  },
  {
    src: "/bio/19.jpg",
    caption: "Collection showcase"
  },
  {
    src: "/bio/20.jpg",
    caption: "Design workshop"
  },
  {
    src: "/bio/22.jpg",
    caption: "Runway preparation"
  },
  {
    src: "/bio/23.jpg",
    caption: "Studio life"
  },
  {
    src: "/bio/24.jpg",
    caption: "Fashion innovation"
  },
  {
    src: "/bio/25.jpg",
    caption: "Legacy collection"
  }
];

// Update the BioGrid to include Bento sections
const BioGrid = styled.div`
  display: grid;
  gap: 4rem;
  margin: 4rem 0;
`;

const BentoSection = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.75rem;
  margin: 2rem 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;
  }
`;

const BentoImage = styled(motion.div)`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  grid-column: ${props => props.span || 'span 4'};
  aspect-ratio: ${props => props.ratio || '1'};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    grid-column: 1 / -1;
  }
`;

const BentoCaption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  font-size: 0.9rem;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${BentoImage}:hover & {
    opacity: 1;
  }
`;

const PageContainer = styled(motion.main)`
  background: #000000;
  color: white;
  min-height: 100vh;
`;

const Hero = styled.section`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
              url('/bio/Hero2.jpg') center top 20% / cover;
  position: relative;

  h1 {
    font-size: clamp(3rem, 8vw, 6rem);
    line-height: 1;
    position: relative;
    z-index: 2;
    
    span {
      display: block;
      font-size: 0.4em;
      opacity: 0.8;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      margin-top: 1rem;
    }
  }
`;

const ContentSection = styled.section`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const TextBlock = styled(motion.div)`
  max-width: 800px;
  margin: 0 auto;

  h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 1.5rem;
  }

  p {
    font-size: clamp(1rem, 1.5vw, 1.2rem);
    line-height: 1.6;
    color: rgba(255,255,255,0.8);
  }
`;

const Quote = styled.blockquote`
  font-size: 1.5rem;
  font-style: italic;
  margin: 2rem 0;
  padding-left: 2rem;
  border-left: 4px solid var(--colors-accent);
  color: rgba(255,255,255,0.9);
`;

const Timeline = styled.div`
  margin: 2rem 0;
`;

const TimelineItem = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 2rem;
  margin-bottom: 1.5rem;
`;

const Year = styled.div`
  font-weight: bold;
  color: var(--colors-accent);
`;

const Event = styled.div`
  color: rgba(255,255,255,0.8);
`;

const Milestone = styled.div`
  margin: 4rem 0;
  
  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--colors-accent);
  }
`;

export default Biography;