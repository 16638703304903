import { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const CollectionsManager = () => {
  const [collections, setCollections] = useState([
    { id: 1, name: 'Bertoua', status: 'published', lastUpdated: '2024-03-15' },
    { id: 2, name: 'Foatelli', status: 'published', lastUpdated: '2024-03-14' },
    { id: 3, name: 'Louis Vuitton', status: 'published', lastUpdated: '2024-03-13' }
  ]);
  const [isAddingNew, setIsAddingNew] = useState(false);

  return (
    <ManagerContainer>
      <ControlPanel>
        <SearchBar>
          <SearchIcon>🔍</SearchIcon>
          <input type="text" placeholder="Search collections..." />
        </SearchBar>
        <AddButton
          onClick={() => setIsAddingNew(true)}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <span>+</span> Add Collection
        </AddButton>
      </ControlPanel>

      <StatsGrid>
        <StatCard>
          <StatValue>3</StatValue>
          <StatLabel>Total Collections</StatLabel>
        </StatCard>
        <StatCard>
          <StatValue>3</StatValue>
          <StatLabel>Published</StatLabel>
        </StatCard>
        <StatCard>
          <StatValue>0</StatValue>
          <StatLabel>Drafts</StatLabel>
        </StatCard>
      </StatsGrid>

      <CollectionsList>
        {collections.map(collection => (
          <CollectionCard
            key={collection.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <CollectionInfo>
              <CollectionName>{collection.name}</CollectionName>
              <CollectionMeta>
                <Status status={collection.status}>
                  <StatusDot />
                  {collection.status}
                </Status>
                <LastUpdated>Updated: {collection.lastUpdated}</LastUpdated>
              </CollectionMeta>
            </CollectionInfo>
            
            <ActionButtons>
              <ActionButton primary>
                <span>✏️</span>
                Edit
              </ActionButton>
              <ActionButton>
                <span>👁️</span>
                Preview
              </ActionButton>
              <ActionButton danger>
                <span>🗑️</span>
                Delete
              </ActionButton>
            </ActionButtons>
          </CollectionCard>
        ))}
      </CollectionsList>

      <AnimatePresence>
        {isAddingNew && (
          <Modal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ModalContent
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
            >
              <ModalHeader>
                <h2>Add New Collection</h2>
                <CloseButton onClick={() => setIsAddingNew(false)}>×</CloseButton>
              </ModalHeader>
              
              <FormGroup>
                <Label>Collection Name</Label>
                <Input type="text" placeholder="Enter collection name" />
              </FormGroup>

              <FormGroup>
                <Label>Description</Label>
                <TextArea placeholder="Enter collection description" />
              </FormGroup>

              <FormGroup>
                <Label>Cover Image</Label>
                <FileInput type="file" accept="image/*" />
              </FormGroup>

              <ModalButtons>
                <Button primary>Create Collection</Button>
                <Button onClick={() => setIsAddingNew(false)}>Cancel</Button>
              </ModalButtons>
            </ModalContent>
          </Modal>
        )}
      </AnimatePresence>
    </ManagerContainer>
  );
};

const ManagerContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ControlPanel = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SearchBar = styled.div`
  flex: 1;
  min-width: 200px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);

  input {
    background: none;
    border: none;
    color: white;
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const SearchIcon = styled.span`
  opacity: 0.5;
  margin-right: 0.5rem;
`;

const AddButton = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: white;
  color: black;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;

  span {
    font-size: 1.2rem;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
`;

const StatValue = styled.div`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.div`
  opacity: 0.7;
  font-size: 0.9rem;
`;

const CollectionsList = styled.div`
  display: grid;
  gap: 1rem;
`;

const CollectionCard = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }
`;

const CollectionInfo = styled.div`
  flex: 1;
`;

const CollectionName = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const CollectionMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: ${props => props.status === 'published' ? '#10B981' : '#6B7280'};
`;

const StatusDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
`;

const LastUpdated = styled.div`
  font-size: 0.9rem;
  opacity: 0.6;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: stretch;
    
    button {
      flex: 1;
    }
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: ${props => {
    if (props.primary) return 'white';
    if (props.danger) return 'rgba(239, 68, 68, 0.1)';
    return 'rgba(255, 255, 255, 0.05)';
  }};
  color: ${props => props.primary ? 'black' : 'white'};
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;

  &:hover {
    background: ${props => {
      if (props.primary) return 'rgba(255, 255, 255, 0.9)';
      if (props.danger) return 'rgba(239, 68, 68, 0.2)';
      return 'rgba(255, 255, 255, 0.1)';
    }};
  }
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
`;

const ModalContent = styled(motion.div)`
  background: #1a1a1a;
  padding: 2rem;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.6;
  
  &:hover {
    opacity: 1;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.8;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  min-height: 100px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const FileInput = styled.input`
  width: 100%;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  cursor: pointer;

  &::-webkit-file-upload-button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  flex: 1;
  padding: 0.8rem;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  background: ${props => props.primary ? 'white' : 'rgba(255, 255, 255, 0.1)'};
  color: ${props => props.primary ? 'black' : 'white'};
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

export default CollectionsManager; 