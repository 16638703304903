import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const NotFound = () => {
  return (
    <NotFoundContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Content>
        <Title>404</Title>
        <Subtitle>Page Not Found</Subtitle>
        <Description>
          Oops! The page you're looking for doesn't exist or has been moved.
        </Description>
        <HomeLink to="/">
          Return to Home
        </HomeLink>
      </Content>
    </NotFoundContainer>
  );
};

const NotFoundContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 2rem;
`;

const Content = styled.div`
  text-align: center;
  max-width: 600px;
`;

const Title = styled.h1`
  font-size: 8rem;
  margin: 0;
  background: linear-gradient(45deg, #f3ec78, #af4261);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin: 0 0 1rem;
`;

const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const HomeLink = styled(Link)`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f3ec78;
  }
`;

export default NotFound; 