import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <FooterContent>
        <BrandMark>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ 
              duration: 1,
              ease: "easeOut"
            }}
          >
            EOG
          </motion.div>
        </BrandMark>
        
        <FooterDivider />
        
        <FooterInfo>
          <FooterText>
            Erickson Oliveira Grady
            <span>Atelier</span>
          </FooterText>
          <ContactInfo>
            <a href="tel:+18505154317">+1 850 515 4317</a>
          </ContactInfo>
          <Copyright>© {currentYear} All rights reserved</Copyright>
        </FooterInfo>
        
        <FooterLinks>
          <Link to="/portfolio">Portfolio</Link>
          <Link to="/contact">Contact</Link>
        </FooterLinks>
      </FooterContent>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background: #000000;
  color: white;
  padding: 4rem 2rem;
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const BrandMark = styled.div`
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.5rem;
  letter-spacing: 0.2em;
  font-weight: 300;
`;

const FooterDivider = styled.div`
  width: 1px;
  height: 3rem;
  background: rgba(255,255,255,0.2);
`;

const FooterInfo = styled.div`
  text-align: center;
`;

const FooterText = styled.div`
  font-size: 1rem;
  letter-spacing: 0.1em;
  margin-bottom: 0.5rem;
  
  span {
    display: block;
    font-family: ${props => props.theme.fonts.heading};
    font-style: italic;
    opacity: 0.8;
  }
`;

const Copyright = styled.div`
  font-size: 0.8rem;
  opacity: 0.6;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 2rem;
  
  a {
    color: white;
    text-decoration: none;
    font-size: 0.9rem;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    
    &:hover {
      opacity: 1;
    }
  }
`;

const ContactInfo = styled.div`
  margin: 0.5rem 0;
  
  a {
    color: white;
    text-decoration: none;
    font-size: 0.9rem;
    opacity: 0.8;
    transition: opacity 0.3s ease;
    letter-spacing: 0.05em;
    
    &:hover {
      opacity: 1;
    }
  }
`;

export default Footer; 