import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import AnnouncementManager from '../components/admin/AnnouncementManager';
import CollectionsManager from '../components/admin/CollectionsManager';
import PressManager from '../components/admin/PressManager';
import HeroManager from '../components/admin/HeroManager';
import ProcessManager from '../components/admin/ProcessManager';

const AdminDashboard = () => {
  const { currentUser, logout } = useAuth();
  const [activeSection, setActiveSection] = useState('collections');

  const sections = [
    { id: 'hero', label: 'Hero Section', icon: '🎨' },
    { id: 'collections', label: 'Collections', icon: '👔' },
    { id: 'press', label: 'Press & Recognition', icon: '📰' },
    { id: 'process', label: 'Process Section', icon: '⚙️' },
    { id: 'announcements', label: 'Announcements', icon: '📢' },
  ];

  const renderSection = () => {
    switch(activeSection) {
      case 'hero': return <HeroManager />;
      case 'collections': return <CollectionsManager />;
      case 'press': return <PressManager />;
      case 'process': return <ProcessManager />;
      case 'announcements': return <AnnouncementManager />;
      default: return <CollectionsManager />;
    }
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <NavItems>
          {sections.map(section => (
            <NavItem 
              key={section.id}
              isActive={activeSection === section.id}
              onClick={() => setActiveSection(section.id)}
              whileHover={{ x: 5 }}
              whileTap={{ scale: 0.98 }}
            >
              <span className="icon">{section.icon}</span>
              {section.label}
              {activeSection === section.id && (
                <ActiveIndicator 
                  layoutId="activeIndicator"
                  initial={false}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                />
              )}
            </NavItem>
          ))}
        </NavItems>

        <LogoutButton 
          onClick={logout}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <span>🚪</span> Logout
        </LogoutButton>
      </Sidebar>

      <MainContent>
        {renderSection()}
      </MainContent>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background: #000;
  padding-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 0;
  }
`;

const Sidebar = styled.aside`
  position: fixed;
  left: 0;
  top: 2rem;
  bottom: 0;
  width: 280px;
  background: rgba(255, 255, 255, 0.03);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  gap: 2rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 1rem;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 100;
    background: #000;
  }
`;

const NavItems = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  margin-top: 3rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 0.5rem;
    
    &::-webkit-scrollbar {
      height: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
    }
  }
`;

const NavItem = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  background: none;
  border: none;
  color: ${props => props.isActive ? 'white' : 'rgba(255, 255, 255, 0.6)'};
  cursor: pointer;
  position: relative;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  white-space: nowrap;

  .icon {
    font-size: 1.2rem;
  }

  &:hover {
    color: white;
  }

  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
    width: auto;
    border-radius: 8px;
  }
`;

const ActiveIndicator = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  z-index: -1;
`;

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
  margin-left: 280px;
  min-height: calc(100vh - 2rem);
  background: #1a1a1a;
  padding-top: 6rem;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 1rem;
    padding-top: 4rem;
    min-height: calc(100vh - 100px);
  }
`;

const LogoutButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  background: rgba(255, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  width: 100%;
  font-size: 1rem;

  @media (max-width: 768px) {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: auto;
    padding: 0.75rem 1.5rem;
    z-index: 1000;
    background: rgba(255, 0, 0, 0.9);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
`;

export default AdminDashboard; 