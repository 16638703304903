import { useState } from 'react';
import * as S from './styles/AdminStyles';

const PressManager = () => {
  const [pressItems, setPressItems] = useState([
    {
      id: 1,
      publication: "Vogue",
      quote: "A visionary designer reshaping the future of fashion",
      logo: "/images/press/vogue.svg"
    },
    {
      id: 2,
      publication: "Harper's Bazaar",
      quote: "Setting new standards in luxury fashion",
      logo: "/images/press/bazaar.svg"
    }
  ]);

  return (
    <S.ManagerContainer>
      <S.TopBar>
        <S.SearchInput placeholder="Search press quotes..." />
        <S.Button primary whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
          <span>+</span> Add Press Quote
        </S.Button>
      </S.TopBar>

      <S.GridLayout>
        {pressItems.map(item => (
          <S.ItemCard
            key={item.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div style={{ flex: 1 }}>
              <h3 style={{ marginBottom: '0.5rem' }}>{item.publication}</h3>
              <p style={{ opacity: 0.7, fontStyle: 'italic' }}>"{item.quote}"</p>
            </div>
            <S.ActionBar>
              <S.Button>Edit</S.Button>
              <S.Button style={{ background: 'rgba(255, 0, 0, 0.1)' }}>Delete</S.Button>
            </S.ActionBar>
          </S.ItemCard>
        ))}
      </S.GridLayout>
    </S.ManagerContainer>
  );
};

export default PressManager; 