import { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TickerContainer = styled.section`
  background: #000000;
  padding: 4rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const TickerHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  
  h3 {
    font-size: clamp(1rem, 2vw, var(--font-size--18px));
    color: var(--colors--grey);
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
`;

const TickerWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 4rem;
  align-items: center;
`;

const BrandLogo = styled.div`
  color: var(--colors--white);
  font-size: clamp(2rem, 5vw, 4rem);
  font-weight: 500;
  white-space: nowrap;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  
  &:hover {
    opacity: 1;
  }
`;

const BrandTicker = () => {
  const [isHovered, setIsHovered] = useState(false);
  
  const brandLogos = [
    "VOGUE", "GQ", "ELLE", "BAZAAR", "VANITY FAIR", 
    "ESQUIRE", "L'OFFICIEL", "W MAGAZINE"
  ];
  
  const duplicatedLogos = [...brandLogos, ...brandLogos];

  return (
    <TickerContainer>
      <TickerHeader>
        <h3>Featured In</h3>
      </TickerHeader>
      
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: "-50%" }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear",
          pause: isHovered
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ display: 'flex' }}
      >
        <TickerWrapper>
          {duplicatedLogos.map((brand, index) => (
            <BrandLogo key={index}>
              {brand}
            </BrandLogo>
          ))}
        </TickerWrapper>
      </motion.div>
    </TickerContainer>
  );
};

export default BrandTicker; 