import { useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Contact = () => {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContactWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <ContactContainer>
        <ContactContent>
          <ContactHeader>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
            >
              Atelier Inquiries
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
            >
              For private consultations and collection previews
            </motion.p>
          </ContactHeader>

          <DirectContact>
            <PhoneContact>
              <h3>Direct Contact</h3>
              <a href="tel:+18505154317">+1 850 515 4317</a>
            </PhoneContact>
            <StoreNotice>
              <h3>Store</h3>
              <p>Coming Soon</p>
            </StoreNotice>
          </DirectContact>

          <ContactForm
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: "easeOut", delay: 0.4 }}
          >
            <FormGroup>
              <FormLabel>Full Name</FormLabel>
              <FormInput 
                type="text" 
                placeholder="Enter your full name"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Email Address</FormLabel>
              <FormInput 
                type="email" 
                placeholder="your@email.com"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Phone Number</FormLabel>
              <FormInput 
                type="tel" 
                placeholder="+1 (___) ___-____"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Nature of Inquiry</FormLabel>
              <FormSelect>
                <option value="" disabled selected>Select the nature of your inquiry</option>
                <option value="private-consultation">Private Consultation</option>
                <option value="collection-preview">Collection Preview</option>
                <option value="custom-design">Custom Design Commission</option>
                <option value="press">Press Inquiry</option>
              </FormSelect>
            </FormGroup>

            <FormGroup>
              <FormLabel>Message</FormLabel>
              <FormTextarea 
                placeholder="Please share the details of your inquiry..."
                rows="5"
              />
            </FormGroup>

            <SubmitButton
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.2 }}
            >
              Submit Inquiry
            </SubmitButton>
          </ContactForm>
        </ContactContent>
      </ContactContainer>
    </ContactWrapper>
  );
};

const ContactWrapper = styled(motion.main)`
  min-height: 100vh;
  background: #000000;
  color: white;
  padding-top: 12rem;
  padding-bottom: 6rem;
`;

const ContactContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const ContactContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const ContactHeader = styled.div`
  text-align: center;
  margin-bottom: 5rem;

  h1 {
    font-family: ${props => props.theme.fonts.heading};
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    margin-bottom: 1.5rem;
    font-weight: 300;
    letter-spacing: 0.02em;
  }

  p {
    font-size: 1.1rem;
    opacity: 0.8;
    letter-spacing: 0.05em;
    font-weight: 300;
  }
`;

const ContactForm = styled(motion.form)`
  display: grid;
  gap: 2.5rem;
`;

const FormGroup = styled.div`
  display: grid;
  gap: 0.8rem;
`;

const FormLabel = styled.label`
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 300;
`;

const inputStyles = `
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.05);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const FormInput = styled.input`
  ${inputStyles}
`;

const FormSelect = styled.select`
  ${inputStyles}
  cursor: pointer;

  option {
    background: #000000;
    color: white;
    padding: 1rem;
  }
`;

const FormTextarea = styled.textarea`
  ${inputStyles}
  resize: vertical;
  min-height: 120px;
`;

const SubmitButton = styled(motion.button)`
  background: white;
  color: black;
  border: none;
  padding: 1.2rem 2.5rem;
  font-size: 1rem;
  letter-spacing: 0.1em;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
`;

const DirectContact = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 4rem;
  text-align: center;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const PhoneContact = styled.div`
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;

  h3 {
    font-size: 0.9rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    opacity: 0.8;
    margin-bottom: 1rem;
    font-weight: 300;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const StoreNotice = styled.div`
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;

  h3 {
    font-size: 0.9rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    opacity: 0.8;
    margin-bottom: 1rem;
    font-weight: 300;
  }

  p {
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    opacity: 0.9;
    font-style: italic;
  }
`;

export default Contact; 