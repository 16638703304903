import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

// Add animation variants
const pageVariants = {
  initial: { 
    opacity: 0,
    y: 20
  },
  animate: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut",
      staggerChildren: 0.2
    }
  },
  exit: { 
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

const itemVariants = {
  initial: { 
    opacity: 0,
    y: 20
  },
  animate: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const Portfolio = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(true);

  const portfolioItems = [
    {
      id: 1,
      title: "Pinstripe Redefined",
      year: "2024",
      category: "Bespoke Tailoring",
      mainImage: "/sustainable/s8.jpg",
      gallery: [
        {
          image: "/Pinstripes/p2.jpg",
          caption: "Architectural pleating meets classic pinstripe"
        },
        {
          image: "/Pinstripes/p4.jpg",
          caption: "Innovative sleeve construction detail"
        },
        {
          image: "/Pinstripes/p1.jpg",
          caption: "Minimalist button and cuff detailing"
        }
      ],
      description: "A revolutionary take on the timeless pinstripe suit, where traditional tailoring meets contemporary architectural design. This collection reimagines the iconic business wear staple through a lens of modern minimalism and innovative pattern cutting.",
      details: [
        "Italian wool with custom-width pinstripes",
        "Architectural pleating techniques",
        "Deconstructed shoulder lines",
        "Hidden placket closures",
        "Engineered sleeve patterns"
      ],
      process: "Each suit begins with traditional Savile Row measurements but diverges into experimental territory through innovative pattern manipulation. The pleating process alone requires over 40 hours of handwork, while the precise stripe matching ensures visual continuity across every seam. The collection marries the precision of classical tailoring with the fluidity of contemporary design."
    },
    {
      id: 2,
      title: "Sustainable Luxury",
      year: "2023",
      category: "Ready-to-Wear",
      mainImage: "/sustainable/s4.jpg",
      gallery: [
        {
          image: "/sustainable/s2.jpg",
          caption: "Sustainable material sourcing"
        },
        {
          image: "/sustainable/s3.jpg",
          caption: "Zero-waste pattern cutting"
        },
        {
          image: "/sustainable/s1.jpg",
          caption: "Natural dyeing process"
        },
       
      ],
      description: "A collection that proves luxury and sustainability can coexist. Using only ethically sourced materials and zero-waste design principles, each piece tells a story of conscious creativity.",
      details: [
        "Organic and recycled fabrics",
        "Natural dye techniques",
        "Zero-waste patterns",
        "Local artisan collaboration",
        "Circular fashion principles"
      ],
      process: "The development focused on minimizing environmental impact while maximizing design innovation. Each piece was created using zero-waste pattern cutting techniques and naturally dyed fabrics."
    },
    {
      id: 3,
      title: "Heritage Reimagined",
      year: "2023",
      category: "Accessories",
      mainImage: "/sustainable/s.jpg",
      gallery: [
        {
          image: "https://images.unsplash.com/photo-1544441893-675973e31985",
          caption: "Traditional craftsmanship meets modern design"
        },
        {
          image: "https://images.unsplash.com/photo-1539109136881-3be0616acf4b",
          caption: "Handcrafted leather details"
        },
        {
          image: "https://images.unsplash.com/photo-1621786030484-4c855eed6974",
          caption: "Artisanal finishing touches"
        }
      ],
      description: "A collection that bridges generations, reimagining traditional accessories with contemporary flair. Each piece honors heritage craftsmanship while embracing modern aesthetics and functionality.",
      details: [
        "Hand-selected Italian leather",
        "Traditional brass hardware",
        "Hand-stitched details",
        "Modern minimalist design",
        "Sustainable production methods"
      ],
      process: "Each piece is crafted using time-honored techniques passed down through generations, combined with innovative design approaches to create accessories that are both timeless and contemporary."
    },
    {
      id: 4,
      title: "Urban Nomad",
      year: "2022",
      category: "Streetwear",
      mainImage: "/sustainable/s9.jpg",
      gallery: [
        {
          image: "https://images.unsplash.com/photo-1552374196-1ab2a1c593e8",
          caption: "Urban mobility meets style"
        },
        {
          image: "https://images.unsplash.com/photo-1544441893-675973e31985",
          caption: "Technical fabric innovation"
        },
        {
          image: "https://images.unsplash.com/photo-1516762689617-e1cffcef479d",
          caption: "Street style essentials"
        }
      ],
      description: "A collection designed for the modern urban explorer, combining technical innovation with street-smart aesthetics. Each piece adapts to the dynamic lifestyle of city dwellers.",
      details: [
        "Weather-resistant materials",
        "Modular design elements",
        "Smart storage solutions",
        "Reflective safety features",
        "Transformable garments"
      ],
      process: "Developed through extensive urban lifestyle research and real-world testing, each piece is engineered to meet the demands of city life while maintaining a strong design aesthetic."
    },
    {
      id: 5,
      title: "Digital Horizon",
      year: "2022",
      category: "Virtual Couture",
      mainImage: "/sustainable/s90.jpg",
      gallery: [
        {
          image: "https://images.unsplash.com/photo-1558769132-cb1aea458c5e",
          caption: "Digital fabric simulation"
        },
        {
          image: "https://images.unsplash.com/photo-1561932850-4b65ce092609",
          caption: "Virtual fitting process"
        },
        {
          image: "https://images.unsplash.com/photo-1558769132-cb1aea458c5e",
          caption: "AR fashion experience"
        }
      ],
      description: "Pioneering the future of fashion through digital innovation. This collection exists at the intersection of physical craftsmanship and virtual reality, creating unique experiences in both digital and physical spaces.",
      details: [
        "3D-modeled garments",
        "AR try-on capability",
        "Digital fabric physics",
        "Virtual customization",
        "Blockchain authentication"
      ],
      process: "Each piece is first created in a digital environment, allowing for unlimited experimentation with form and physics before being translated into physical garments."
    },
    {
      id: 6,
      title: "Atelier Collection",
      year: "2021",
      category: "Bespoke",
      mainImage: "/sustainable/s91.jpg",
      gallery: [
        {
          image: "https://images.unsplash.com/photo-1490481651871-ab68de25d43d",
          caption: "Bespoke fitting process"
        },
        {
          image: "https://images.unsplash.com/photo-1621786030484-4c855eed6974",
          caption: "Hand-finishing details"
        },
        {
          image: "https://images.unsplash.com/photo-1544441893-675973e31985",
          caption: "Final garment presentation"
        }
      ],
      description: "A celebration of individual style through the art of bespoke tailoring. Each piece is uniquely crafted to the client's specifications, ensuring a perfect blend of personal style and expert craftsmanship.",
      details: [
        "Personal measurement system",
        "Custom fabric selection",
        "Hand-finished details",
        "Multiple fitting sessions",
        "Lifetime alterations"
      ],
      process: "Every garment begins with an extensive consultation and measurement session, followed by multiple fittings to ensure perfect execution of the client's vision."
    }
  ];

  return (
    <PageContainer
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <ContentSection>
        <IntroSection
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <IntroContent>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
            >
              Portfolio
            </motion.h1>
            <IntroText
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
            >
              <p>Rooted in the rich sartorial traditions of Verona, Italy, our collections embody the perfect fusion of Italian craftsmanship and contemporary design. Each piece carries the essence of our heritage while embracing modern innovation, creating a dialogue between traditional artisanship and forward-thinking fashion.</p>
              <p>From our early foundations in Italy's prestigious fashion landscape to our current presence in the United States, our work continues to honor the meticulous attention to detail and pursuit of excellence that defines Italian haute couture.</p>
            </IntroText>
          </IntroContent>
        </IntroSection>

        <PortfolioGrid>
          {portfolioItems.map(item => (
            <PortfolioItem
              key={item.id}
              layoutId={`item-${item.id}`}
              onClick={() => setSelectedId(item.id)}
            >
              <motion.img 
                src={item.mainImage} 
                alt={item.title}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
              <ItemInfo>
                <h2>{item.title}</h2>
                <span>{item.year}</span>
                <p>{item.category}</p>
              </ItemInfo>
            </PortfolioItem>
          ))}
        </PortfolioGrid>

        {selectedId && (
          <PortfolioModal
            layoutId={`item-${selectedId}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CloseButton onClick={() => setSelectedId(null)}>×</CloseButton>
            <ModalContent>
              {portfolioItems.find(item => item.id === selectedId) && (
                <>
                  <motion.img 
                    src={portfolioItems.find(item => item.id === selectedId).mainImage}
                    alt={portfolioItems.find(item => item.id === selectedId).title}
                  />
                  <TextContent>
                    <h2>{portfolioItems.find(item => item.id === selectedId).title}</h2>
                    <span>{portfolioItems.find(item => item.id === selectedId).year}</span>
                    <p>{portfolioItems.find(item => item.id === selectedId).description}</p>
                    
                    <h3>Gallery</h3>
                    <GalleryGrid>
                      {portfolioItems.find(item => item.id === selectedId).gallery.map((image, index) => (
                        <GalleryItem key={index}>
                          <img src={image.image} alt={image.caption} />
                          <GalleryCaption>{image.caption}</GalleryCaption>
                        </GalleryItem>
                      ))}
                    </GalleryGrid>

                    <h3>Details</h3>
                    <ul>
                      {portfolioItems.find(item => item.id === selectedId).details.map((detail, index) => (
                        <li key={index}>{detail}</li>
                      ))}
                    </ul>
                    
                    <h3>Process</h3>
                    <p>{portfolioItems.find(item => item.id === selectedId).process}</p>
                  </TextContent>
                </>
              )}
            </ModalContent>
          </PortfolioModal>
        )}
      </ContentSection>
    </PageContainer>
  );
};

const PageContainer = styled(motion.div)`
  min-height: 100vh;
  padding: 8rem 0 6rem;
  background: #000000;
  color: white;
`;

const Hero = styled.section`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #000000, #1a1a1a);

  h1 {
    font-size: clamp(3rem, 8vw, 6rem);
    line-height: 1;
    color: white;
    font-family: 'Playfair Display', serif;
    
    span {
      display: block;
      font-size: 0.4em;
      opacity: 0.8;
      font-family: 'Raleway', sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      margin-top: 1rem;
    }
  }
`;

const ContentSection = styled.section`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
`;

const PortfolioItem = styled(motion.div)`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  background: #111;
  cursor: pointer;

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
`;

const ItemInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(transparent, rgba(0,0,0,0.9));
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  span {
    display: block;
    font-size: 0.9rem;
    opacity: 0.8;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    opacity: 0.7;
  }
`;

const PortfolioModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.9);
  z-index: 100;
  overflow-y: auto;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ModalContent = styled.div`
  max-width: 1000px;
  margin: 2rem auto;
  background: #111;
  border-radius: 24px;
  overflow: hidden;

  img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
  }
`;

const TextContent = styled.div`
  padding: 2rem;

  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  span {
    display: block;
    font-size: 1rem;
    opacity: 0.8;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    opacity: 0.8;
    margin-bottom: 1.5rem;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: 1.1rem;
      opacity: 0.8;
      margin-bottom: 0.5rem;
      padding-left: 1.5rem;
      position: relative;

      &:before {
        content: "•";
        position: absolute;
        left: 0;
        color: var(--colors-accent);
      }
    }
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 2rem;
  right: 2rem;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 101;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(10px);

  &:hover {
    background: rgba(255,255,255,0.1);
  }
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin: 2rem 0;
`;

const GalleryItem = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
`;

const GalleryCaption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  font-size: 0.9rem;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${GalleryItem}:hover & {
    opacity: 1;
  }
`;

const IntroSection = styled(motion.div)`
  padding: 0 2rem;
  margin-bottom: 4rem;
`;

const IntroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: clamp(2.5rem, 5vw, 4rem);
    margin-bottom: 2rem;
    font-weight: 300;
    letter-spacing: 0.02em;
    font-family: ${props => props.theme.fonts.heading};
  }
`;

const IntroText = styled(motion.div)`
  p {
    font-size: clamp(1.1rem, 1.5vw, 1.25rem);
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 1.5rem;
    font-weight: 300;
    letter-spacing: 0.02em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  max-width: 700px;
  margin: 0 auto;
`;

export default Portfolio; 