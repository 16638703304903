import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Biography from './pages/Biography';
import Contact from './pages/Contact';
import AdminDashboard from './pages/AdminDashboard';
import Login from './components/auth/Login';
import PrivateRoute from './components/PrivateRoute';
import GlobalStyles from './styles/GlobalStyles';
import LouisVuittonCollection from './pages/collections/LouisVuittonCollection';
import BertouaCollection from './pages/collections/BertouaCollection';
import FoatelliCollection from './pages/collections/FoatelliCollection';
import Footer from './components/Footer';
import styled from 'styled-components';
import NotFound from './pages/NotFound';
import { useEffect } from 'react';

const theme = {
  colors: {
    primary: '#000000',
    secondary: '#FFFFFF',
    accent: '#FF4081',
    background: '#F5F5F5',
    text: '#333333'
  },
  fonts: {
    heading: "'Playfair Display', serif",
    body: "'Raleway', sans-serif"
  }
};

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
`;

function AnimatedRoutes() {
  const location = useLocation();

  useEffect(() => {
    // Save the current scroll position before navigating
    if (window.history.state) {
      window.history.state.scroll = window.scrollY;
    }
    
    // Scroll to top on new page navigation
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Handle browser back/forward
  useEffect(() => {
    const handlePopState = () => {
      // Restore scroll position if it exists in history state
      if (window.history.state && window.history.state.scroll) {
        window.scrollTo(0, window.history.state.scroll);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  return (
    <PageWrapper>
      <Navbar />
      <MainContent>
        <AnimatePresence mode='wait'>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/biography" element={<Biography />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/collections/louis-vuitton-2022" element={<LouisVuittonCollection />} />
            <Route path="/collections/bertoua-2023" element={<BertouaCollection />} />
            <Route path="/collections/foatelli-2024" element={<FoatelliCollection />} />
            <Route
              path="/admin/*"
              element={
                <PrivateRoute>
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
            <Route component={NotFound} />
          </Routes>
        </AnimatePresence>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
}

function App() {
  return (
    <>
      <GlobalStyles />
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <AnimatedRoutes />
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;
