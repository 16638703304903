import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuVariants = {
    closed: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    },
    open: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut",
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const menuItemVariants = {
    closed: { opacity: 0, x: -20 },
    open: { opacity: 1, x: 0 }
  };

  const handleClickOutside = (e) => {
    if (isOpen && e.target.closest('.mobile-menu-inner') === null) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <Nav>
      <NavContainer>
        <NavContent>
          <LogoLink to="/">
            <LogoContainer>
              <LogoText
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, ease: "easeOut" }}
              >
                <span className="main">EOG</span>
                <span className="sub">ATELIER</span>
              </LogoText>
            </LogoContainer>
          </LogoLink>

          <DesktopMenu>
            <NavLink to="/portfolio">Portfolio</NavLink>
            <NavLink to="/biography">Biography</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </DesktopMenu>

          <MenuButton 
            onClick={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
          >
            <span className="sr-only">Open menu</span>
            <MenuLine isOpen={isOpen} position="top" />
            <MenuLine isOpen={isOpen} position="middle" />
            <MenuLine isOpen={isOpen} position="bottom" />
          </MenuButton>
        </NavContent>
      </NavContainer>

      <MobileMenu
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={menuVariants}
        onClick={handleClickOutside}
      >
        <MobileMenuInner className="mobile-menu-inner">
          <MobileMenuItem
            variants={menuItemVariants}
            onClick={() => setIsOpen(false)}
          >
            <NavLink to="/portfolio">Portfolio</NavLink>
          </MobileMenuItem>
          <MobileMenuItem
            variants={menuItemVariants}
            onClick={() => setIsOpen(false)}
          >
            <NavLink to="/biography">Biography</NavLink>
          </MobileMenuItem>
          <MobileMenuItem
            variants={menuItemVariants}
            onClick={() => setIsOpen(false)}
          >
            <NavLink to="/contact">Contact</NavLink>
          </MobileMenuItem>
        </MobileMenuInner>
      </MobileMenu>
    </Nav>
  );
};

// Add these new styled components for the logo
const LogoLink = styled(Link)`
  text-decoration: none;
  color: white;
  display: block;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LogoText = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1;

  .main {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 1.8rem;
    letter-spacing: 0.2em;
    font-weight: 300;
  }

  .sub {
    font-size: 0.7rem;
    letter-spacing: 0.4em;
    opacity: 0.8;
    margin-top: 0.2rem;
    font-weight: 300;
  }
`;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background: transparent;
  padding: 2rem;
  font-size: var(--font-size--18px);
`;

const NavContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
`;

const DesktopMenu = styled.div`
  display: none;
  gap: 2rem;
  margin-left: auto;
  
  @media (min-width: 768px) {
    display: flex;
  }
`;

const NavLink = styled(Link)`
  color: var(--colors--white);
  text-decoration: none;
  transition: color 0.2s;
  
  &:hover {
    color: var(--colors--grey);
  }
`;

const MenuButton = styled.button`
  display: none;
  width: 30px;
  height: 30px;
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 51;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const MenuLine = styled.div`
  width: 100%;
  height: 2px;
  background: white;
  transition: all 0.3s ease;
  position: absolute;
  left: 0;

  ${({ position, isOpen }) => {
    if (position === 'top') {
      return `
        top: ${isOpen ? '50%' : '20%'};
        transform: ${isOpen ? 'rotate(45deg)' : 'none'};
      `;
    }
    if (position === 'middle') {
      return `
        top: 50%;
        transform: translateY(-50%);
        opacity: ${isOpen ? 0 : 1};
      `;
    }
    if (position === 'bottom') {
      return `
        bottom: ${isOpen ? '50%' : '20%'};
        transform: ${isOpen ? 'rotate(-45deg)' : 'none'};
      `;
    }
  }}
`;

const MobileMenu = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  z-index: 50;
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    visibility: ${props => props.animate === "open" ? 'visible' : 'hidden'};
  }
`;

const MobileMenuInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
`;

const MobileMenuItem = styled(motion.div)`
  a {
    font-size: 2rem;
    color: white;
    text-decoration: none;
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 2px;
      background: white;
      transition: width 0.3s ease;
    }
    
    &:hover::after {
      width: 100%;
    }
  }
`;

export default Navbar; 